import { render, staticRenderFns } from "./app-wc-movement.vue?vue&type=template&id=86f38622&scoped=true&"
import script from "./js/app-wc-movement.js?vue&type=script&lang=js&"
export * from "./js/app-wc-movement.js?vue&type=script&lang=js&"
import style0 from "./app-wc-movement.vue?vue&type=style&index=0&id=86f38622&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86f38622",
  null
  
)

export default component.exports